import { Col, Row } from "react-bootstrap"
// import { Link } from "react-router-dom"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import Button from "../Button"
// import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import heroPic from "../../assets/img/headerimage.svg"
import SearchTest from "../SearchTest"

// need to fix overflow here (hide-of) is fixing for now

const HeroHeader = () => {
  return (
    <section className="pb-8" id="home">
      <div className="container-sm-lg hero-header-container px-lg-3 px-xxl-3 hide-of">
        <Row className="align-items-center ">
          <Col
            xs={12}
            lg="auto"
            className="text-end order-0 order-md-1 order-1"
          >
            <div className="position-relative p-5 p-md-7 d-lg-none">
              <div className="position-relative ">
                <img
                  className="w-100 shadow-lg d-dark-none rounded-2 shadow-lg"
                  src={heroPic} // lightmode image, when screen gets smaller
                  alt="hero-header"
                />
              </div>
            </div>
            <div
              className="hero-image-container position-absolute
             top-0 bottom-0 end-0 d-none d-lg-block"
            >
              <div className="position-relative h-100 w-80 ">
                <div
                  className="position-absolute h-100  d-flex align-items-center end-0 style-hero-lg-pic"
                  // style={{ top: "-50px" }}
                >
                  <img
                    style={{ zIndex: 1 }}
                    className="pt-7 shadow-lg pt-md-0 w-100 shadow-lg d-dark-none rounded-2  "
                    src={heroPic} // lightmode image when screen is full
                    alt="hero-header"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="text-lg-end text-llg-end   text-center pt-8 pb-6 order-0 position-relative"
          >
            <div className="">
              <h1 className="fs-3 ps-lg-10 ps-xl-0  text-llg-end fs-lg-3 fs-md-1 fs-lg-2 fs-xl-3 fw-semi-bold mb-4 ">
                <span className="text-primary opacity-90 d-lg-inline-nowrap text-llg-end  ">
                  Busca, compara y contrata
                </span>
                <br />
                <div className="text-fiestr-pink text-llg-end">
                  <div className=""> los mejores proveedores </div>
                </div>
              </h1>
              <div className=" ">
                <div className="text-fiestr-gray fs-8 fw-medium ps-lg-10 ps-xl-0 after-a">
                  Desde bodas hasta fiestas de cumpleaños, te ayudamos{" "}
                  <div> a contratar con confianza y seguridad</div>
                </div>
              </div>
            </div>
          </Col>
          <div className=" d-flex justify-content-center  style-hero-sm-pic">
            <div
              className="pill-container align-content-center    "
              style={{ zIndex: 0 }}
            >
              <div className="">
                <div className="positon-absolute ps-xxl-8 ps-xl-8  center-search">
                  <SearchTest />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </section>
  )
}

export default HeroHeader
