import { useAuth0 } from "@auth0/auth0-react"
import Button from "../common/Button"

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0()

  return (
    isAuthenticated && ( // if the user is  authenticated yet(logged in), show the button
      <Button
        className="btn btn-link p-0 text-900 order-1 order-lg-0"
        onClick={() => logout()}
      >
        Logout
      </Button>
    )
  )
}
export default LogoutButton
