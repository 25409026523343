import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import fiestrLogo from "../../assets/img/icons/logo.svg"
// there is currently overflow on this comp

function Footer() {
  // const [showModal, setShowModal] = useState(false) -> custruction modal
  // const handleShowModal = () => {
  //   setShowModal(true)
  // }

  // const handleCloseModal = () => {
  //   setShowModal(false)
  // }

  return (
    <section className="footer-bg hide-of  ">
      <footer>
        <Row className="text-start-left px-5 hide-of ">
          <Col className="text-card-cat fw-medium text-decoration-none ">
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat ">Nostros</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat">Registrarme</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat">Proveedores</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat">Crear un evento</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat">Preguntas Frecuentes</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="mb-1 text-card-cat">Contactos</div>
            </Link>
          </Col>
          <Col className="text-center d-flex flex-column justify-content-between">
            <div>
              <img className="" src={fiestrLogo} alt="comp" />
            </div>
            <div>
              <div className="text-fiestr-pink fw-medium">
                Celebramos juntos
              </div>
            </div>
            <div>{/* <div>redes-sociales</div> */}</div>
            <div className="mt-auto">
              <Link className="text-decoration-none" to="/">
                <div className="text-card-cat fw-medium">2024-fiestr.com</div>
              </Link>
            </div>
          </Col>
          <Col className="d-flex justify-content-end align-items-end flex-column">
            <div>
              <Link className="text-decoration-none" to="/">
                <div className="text-card-cat fw-medium">
                  Políticas de privacidad
                </div>
              </Link>
            </div>
            <div>
              <Link className="text-decoration-none" to="/">
                <div className="text-card-cat fw-medium">
                  Terminos y Condiciones
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </footer>
    </section>
  )
}

export default Footer
