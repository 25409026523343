import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App.tsx"
import { Auth0Provider } from "@auth0/auth0-react"

const domain = import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN as string
const clientId = import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID as string

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        // redirect_uri: "http://localhost:5173",
        redirect_uri: "https://fiestr.com/", // for production
        audience: "https://fiestr.com/api",
        scope: "openid email profile",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
)
