import { useAuth0 } from "@auth0/auth0-react"
import LogoutButton from "./LogoutButton"

const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0()

  async function fetchData() {
    const token = await getAccessTokenSilently()
    console.log(token)
  }

  if (isLoading) {
    return <div>Loading ...</div>
  }

  return (
    isAuthenticated && (
      <div>
        // <img src={user?.picture} alt={user?.name} />
        // <h2>{user?.name}</h2>
        // <p>{user?.email}</p>
        // <div> {JSON.stringify(user)}</div>
        <button onClick={() => fetchData()}>Fetch Data</button>
        <LogoutButton />
      </div>
    )
  )
}

export default Profile
// test to see if user was able to get access token and view user log in details, not used right now as this is only called in HomePage
