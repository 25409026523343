import Card from "react-bootstrap/Card"

const CategoryCard: React.FC<{ image: string; cardName: string }> = ({
  image,
  cardName,
}) => {
  return (
    <Card className="w-catCard cardBg mb-5 shadow-lg">
      <div>
        <Card.Img className="rounded-3" variant="top" src={image} />
      </div>
      <div className="fw-medium text-center cardBg h-catCard rounded-3">
        <div className="text-card-cat text-center pt-1 px-1 ">{cardName}</div>
      </div>
    </Card>
  )
}

export default CategoryCard
