import { createBrowserRouter } from "react-router-dom"
import LandingPage from "./pages/landing/LandingPage"
import Home from "./pages/userhome/HomePage"

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/home",
    element: <Home />,
  },
])

export { router }
