import NavBar from "../../components/landing/NavBar"
import HeroHeader from "../../components/landing/HeroHeader"
import Category from "../../components/categoryCard/Category"
import Details from "../../components/landing/Details"
import Features from "../../components/landing/Features"
import Footer from "../../components/landing/Footer"

export default function LandingPage() {
  return (
    <div className="bg-white">
      <NavBar />
      <HeroHeader />
      <Category />
      <Details />
      <Features />
      <Footer />
      {/* <SearchTest /> TESTING SEARCH IN HERO SECTION */}
    </div>
  )
}
