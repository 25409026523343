import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import construct from "../../assets/img/Illustrations.svg"

interface MyModalProps {
  show: boolean // Indicates whether the modal should be shown or not
  onHide: () => void // Function to handle modal hiding
  // Add more props as needed
}

const myModal = (props: MyModalProps) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <img
          className="justify-content-center align-items-center "
          src={construct} // bg28 -> lightmode dashboard
          alt="hero-header"
        />
        <h4>Estamos preparando todo</h4>
        <p className="title-center ">
          Por el momento esta función no está disponible. ¡ pero pronto lo
          estará!
        </p>
        <Button variant="link" onClick={props.onHide}>
          Regresar
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default myModal
