import { Col, Row } from "react-bootstrap"
import CategoryCard from "../categoryCard/CategoryCard"
import mobilario from "../../assets/img/categories/mobilario.svg"
import salones from "../../assets/img/categories/salonesCard.svg"
import decor from "../../assets/img/categories/decoracion.svg"
import floristas from "../../assets/img/categories/floristas.svg"
import fotografos from "../../assets/img/categories/fotografo.svg"
import musica from "../../assets/img/categories/musica.svg"
import alimentos from "../../assets/img/categories/alimentos.svg"
import meseros from "../../assets/img/categories/meseros.svg"
import planners from "../../assets/img/categories/planners.svg"
import coctels from "../../assets/img/categories/coctel.svg"
import { Link } from "react-router-dom"
import { useState } from "react"
import MyModal from "../common/MyModal"
const Category = () => {
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  return (
    <section className="bg-soft">
      <div className="hide-of">
        <Row className="align-items-center hide-of">
          <Col className="text-center mb-5">
            <h2 className="text-primary mb-2">Busca por categorías</h2>
            <div className="fs-9 text-break">
              Encuentra cientos de proveedores en nuestras diferentes categorías
            </div>
          </Col>
        </Row>
      </div>
      <div className="container-lg px-xxl-20  ">
        <Row xs={2} md={5} lg={5} className="">
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={salones} cardName="Salones" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={mobilario} cardName="Mobilario" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={decor} cardName="Decoración" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={floristas} cardName="Floristas" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              style={{ textDecoration: "none" }}
              to="/"
              onClick={handleShowModal} // feature under construction modal
            >
              <CategoryCard image={fotografos} cardName="Fotografos" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={musica} cardName="Música" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={alimentos} cardName="Alimentos" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={meseros} cardName="Meseros" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={planners} cardName="Wedding planners" />
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <Link
              onClick={handleShowModal} // feature under construction modal
              style={{ textDecoration: "none" }}
              to="/"
            >
              <CategoryCard image={coctels} cardName="Coctelería" />
            </Link>
          </Col>
        </Row>
        <MyModal show={showModal} onHide={handleCloseModal} />
        {/*  feature under construction modal */}
      </div>
      <div className="hide-of">
        <Row className="align-items-center hide-of">
          <Col className="text-center ">
            <h2 className="text-primary opacity-50">... y mucho más</h2>
          </Col>
        </Row>
      </div>
    </section>
  )
}
export default Category
