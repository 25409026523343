import { Col, Row } from "react-bootstrap"
import mobile from "../../assets/img/features/mobile.svg"
import calender from "../../assets/img/features/009-wedding date.svg"
import computer from "../../assets/img/features/information.svg"
import increase from "../../assets/img/features/increase.svg"
import banquethall from "../../assets/img/features/Canva - Events Banquet Hall 5.svg"
import banquethall2 from "../../assets/img/features/Canva - Events Banquet Hall 5 (1).svg"
import medal from "../../assets/img/features/medal.svg"
import top from "../../assets/img/features/review (1).svg"
import plan from "../../assets/img/features/039-wedding planning (1).svg"
import agree from "../../assets/img/features/agreement.svg"
import { Link } from "react-router-dom"
import MyModal from "../common/MyModal"
import { useState } from "react"
// here, each section has 1 row, with 1 column, and insside the column we have tile, caption, and 2 rows. inside these two rows we have 2 columns each, with an image and a caption. resuable components can be used for the images and captions
function Features() {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => {
    setShowModal(true)
  }
  return (
    <>
      <section className="bg-soft pt-15 border-bottom border-top">
        <div className="container-small">
          <Row>
            <Col>
              <h4 className="text-uppercase">Para</h4>
              <h1 className="fw-black text-gradient-info text-uppercase">
                Proveedores
              </h1>
              <div className="text-fiestr-gray pb-6 pt-2">
                Consolidados que quieren mejorar su operación y pequeños
                negocios que buscan crecer y expandirse.
              </div>
              <div>
                <Row className="text-center">
                  <Col className="">
                    <img className="" src={mobile} alt="mobile" />
                    <div
                      className="fw-medium pt-3"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Recibe mas pedidos
                    </div>
                  </Col>
                  <Col className="">
                    <img className="" src={calender} alt="calender" />
                    <div className="fw-medium pt-3 ">Llena tu calendario</div>
                  </Col>
                </Row>
                <Row className="text-center py-7">
                  <Col className="">
                    <img className="" src={computer} alt="comp" />
                    <div className="fw-medium  pt-3">Mejora tu operación</div>
                  </Col>
                  <Col>
                    <img className="" src={increase} alt="increase" />
                    <div className="fw-medium pt-3">Aumenta tus ventas</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={12}
              lg="6"
              md="6"
              sm="12"
              className="pt-2 pt-xxl-2 pt-xl-2 pt-lg-17 pt-md-20 pt-sm-5"
            >
              <div className="fw-medium text-center">
                <img className="w-90" src={banquethall} alt="Musician" />
              </div>
              <div className="text-center text-primary pt-5 fw-medium">
                <Link onClick={handleShowModal} to="/">
                  Registrame como Proveedor
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className=" bg-soft pt-10 border-top border-bottom">
        <div className="container-small">
          <Row>
            <Col className="order-lg-1">
              <h4 className="text-uppercase">Para</h4>
              <h1 className="fw-black text-gradient-info text-uppercase">
                Organizadores
              </h1>
              <div className="text-fiestr-gray pb-6 pt-2">
                No importa si es tu propia fiesta o eres un organizador
                profesional, nuestras herramientas te ayudarán a hacer que tu
                evento sea todo un exito.
              </div>
              <div>
                <Row className="text-center">
                  <Col className="">
                    <img className="" src={medal} alt="mobile" />
                    <div
                      className="fw-medium pt-3"
                      // style={{ whiteSpace: "nowrap" }}
                    >
                      Encuentra proveedores validados
                    </div>
                  </Col>
                  <Col className="">
                    <img className="" src={top} alt="calender" />
                    <div className="fw-medium pt-3 ">
                      Reseñas de proveedores
                    </div>
                  </Col>
                </Row>
                <Row className="text-center py-7">
                  <Col className="">
                    <img className="" src={plan} alt="comp" />
                    <div className="fw-medium  pt-3">
                      Crea y administra eventos
                    </div>
                  </Col>
                  <Col>
                    <img className="" src={agree} alt="increase" />
                    <div className="fw-medium pt-3">Contrata con confianza</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={12}
              lg="6"
              md="6"
              sm="12"
              className="pt-2 pt-xxl-2 pt-xl-2 pt-lg-17   pt-md-20 pt-sm-5"
            >
              <div className="fw-medium text-center">
                <img className="w-90" src={banquethall2} alt="Musician" />
              </div>
              <div className="text-center text-primary pt-5 fw-medium">
                <Link onClick={handleShowModal} to="/">
                  Registrame como Organizador
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <MyModal show={showModal} onHide={handleCloseModal} />
      </section>
    </>
  )
}

export default Features
