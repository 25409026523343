import { useAuth0 } from "@auth0/auth0-react"
import Button from "../common/Button"

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  return (
    !isAuthenticated && ( // if the user is not authenticated yet, show the button
      <Button
        className="btn btn-link p-0 text-900 order-1 order-lg-0"
        onClick={() => loginWithRedirect()}
      >
        Iniciar Session
      </Button>
    )
  )
}
export default LoginButton
