import classNames from "classnames"
// import avatar from "assets/img/team/40x40/avatar.webp"
import { PropsWithChildren } from "react"
import { useAuth0 } from "@auth0/auth0-react"

export type Size = "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "l" | "m" | "s"
export type Variant = "image" | "name" | "emoji"
export type Rounded = "circle" | "square" | "soft"
export type Status = "online" | "offline" | "away" | "do-not-disturb"
interface AvatarProps {
  size: Size
  src?: string
  variant?: Variant
  rounded?: Rounded
  status?: Status
  placeholder?: boolean
  thumbnail?: boolean
  imageClassName?: string
  className?: string
}
// interface AvatarGroupProps {
//   className?: string
//   total?: number
//   size: Size
// }

const Avatar = ({
  size,
  src,
  variant = "image",
  rounded = "circle",
  status,
  className,
  imageClassName,
  thumbnail,
  children,
  placeholder,
}: PropsWithChildren<AvatarProps>) => {
  const { isAuthenticated } = useAuth0()

  return (
    isAuthenticated && ( // if user is authenticated, show the avatar
      <div
        className={classNames(className, `avatar avatar-${size}`, {
          [`status-${status}`]: status,
        })}
      >
        {variant === "image" && ( // for user profile image
          <img
            // src={src ? src : avatar} -> for custom image
            alt="avatar"
            className={classNames(imageClassName, {
              "img-thumbnail bg-white": thumbnail,
              "avatar-placeholder": !src || placeholder,
              "rounded-circle": rounded === "circle",
              "rounded-soft": rounded === "soft",
            })}
          />
        )}

        {variant === "name" && (
          <div
            className={classNames("avatar-name", {
              "rounded-circle": rounded === "circle",
              "rounded-soft": rounded === "soft",
            })}
          >
            <span>{children}</span>
          </div>
        )}
      </div>
    )
  )
}
export default Avatar
