import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { Row } from "react-bootstrap"
// import { faSearch } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MyModal from "./common/MyModal"
// Import additional components for displaying search results if needed
const SearchTest = () => {
  const [location, setLocation] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value)
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // Perform search based on location and searchTerm
    console.log(`Searching for "${searchTerm}" in "${location}"`)
    // Replace console.log with your logic to fetch or display results
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="center-search search-bar ps-lg-6 ps-xl-2 ">
          <Col xs={10} md={12} lg={7} xl={4} className="p-0">
            <div className="combined-search search-bar shadow-lg bg-white p-1 ">
              <Form.Control
                type="text"
                placeholder="Category"
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="search-field search-bar"
              />
              <Form.Control
                type="text"
                placeholder="Location"
                value={location}
                onChange={handleLocationChange}
                className=" search-field search-bar border-start "
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            xl={2}
            className=" p-0 pt-1  center-search pt-sm-1 pt-lg-0 button-md-pad"
          >
            <Button
              className="shadow-lg"
              onClick={handleShowModal}
              variant="primary"
              type="submit"
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <MyModal show={showModal} onHide={handleCloseModal} />
    </div>
  )
}

export default SearchTest
