import { Navbar, Nav } from "react-bootstrap"
import classNames from "classnames"
import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import Logo from "../../Logo"
// import FeatherIcon from "feather-icons-react"
import Button from "../common/Button"
import ThemeToggler from "../ThemeToggler"
import SearchBox from "../SearchBox"
import { useState } from "react"
import LoginButton from "../auth/LoginButton"
import LogoutButton from "../auth/LogoutButton"
import { useAuth0 } from "@auth0/auth0-react"
import Avatar from "../common/Avatar"
import RegisterButton from "../auth/RegisterButton"
import MyModal from "../common/MyModal"

const NavItem = ({
  label,
  url,
  isLast,
}: {
  label: string
  url: string
  isLast?: boolean
}) => {
  return (
    <Nav.Item
      as="li"
      className={classNames({ "border-bottom border-bottom-lg-0": !isLast })}
    >
      <Nav.Link href={url} className="lh-1 py-0 fs-9 fw-medium py-3">
        {label}
      </Nav.Link>
    </Nav.Item>
  )
}

const LandingNavBar = ({ className }: { className?: string }) => {
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const { isAuthenticated } = useAuth0()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [openSearchModal, setOpenSearchModal] = useState(false)

  useEffect(() => {
    const toggleShadowClass = () => {
      if (window.scrollY > 300) {
        containerRef.current?.classList.add("navbar-shadow")
      } else {
        containerRef.current?.classList.remove("navbar-shadow")
      }
    }

    document.addEventListener("scroll", () => toggleShadowClass())

    return () => document.removeEventListener("scroll", toggleShadowClass)
  }, [])

  return (
    <>
      <div
        className={classNames(className, "bg-white sticky-top landing-navbar")}
        ref={containerRef}
      >
        <Navbar className="px-3 px-lg-7 px-xxl-3 container-small" expand="lg">
          <Navbar.Brand
            as={Link}
            to="/"
            // className="text-decoration-none flex-1 flex-lg-grow-0" // this is for styled 'phoenix
          >
            <Logo />
          </Navbar.Brand>
          <ThemeToggler className="mx-2 d-lg-none" />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <div className="border-bottom d-lg-none mb-2">
              <SearchBox
                placeholder="Search"
                className="w-100"
                inputClassName="rounded-pill my-4"
              />
            </div>
            <Nav
              onClick={handleShowModal}
              className="me-auto mb-2 mb-lg-0"
              as="ul"
            >
              <NavItem label="Inicio" url="" />
              <NavItem label="Crear un Evento" url="" />
              <NavItem label="Busca Proveedores" isLast url="" />
              {/* <NavItem label="Nosotros" url="#us" isLast /> */}
            </Nav>

            <div className="d-grid d-lg-flex gap-4 align-items-center">
              <ThemeToggler className="d-none d-lg-block" />
              <Button
                className="p-0 text-700 hover-text-1100 d-none d-lg-inline lh-sm"
                onClick={() => setOpenSearchModal(!openSearchModal)}
              >
                {/* <FeatherIcon icon="search" size={20} /> */}
              </Button>
              {!isAuthenticated && (
                <>
                  <Link
                    to=""
                    className="btn btn-link p-0 text-900 order-1 order-lg-0"
                  >
                    <LoginButton />
                  </Link>

                  <Link to="" className="">
                    <RegisterButton />
                  </Link>
                </>
              )}
              {isAuthenticated && ( // logout, only appears when user is authenticated
                <Link
                  to="#!"
                  className="btn btn-link p-0 text-900 order-1 order-lg-0"
                >
                  <LogoutButton />
                </Link>
              )}
              {isAuthenticated && ( // user avatar
                <Link
                  to="#!"
                  className="btn btn-link p-0 text-900 order-1 order-lg-0"
                >
                  <Avatar size="xl" variant="name">
                    A
                  </Avatar>
                </Link>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar>
        <MyModal show={showModal} onHide={handleCloseModal} />
      </div>
    </>
  )
}

export default LandingNavBar
