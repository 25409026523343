import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import muscian from "../../assets/img/details/032-musician.svg"
import catering from "../../assets/img/details/019-catering.svg"
import photographer from "../../assets/img/details/028-photographer.svg"
import party from "../../assets/img/details/003-party.svg"
import weddingplan from "../../assets/img/details/039-wedding planning.svg"
import review from "../../assets/img/details/review.svg"
import weddingphoto from "../../assets/img/details/038-wedding photo.svg"
import champagne from "../../assets/img/details/champagne 1.svg"
import bg from "../../assets/img/details/046-bride and groom.svg"

function Details() {
  return (
    <section className="bg-white pt-18 pb-15" id="details">
      <div className=" container-small px-md-11 px-sm-11 px-lg-12 px-xl-12 px-xxl-12">
        <Row className="align-items-center">
          <Col className="text-center mb-5">
            <h2 className="text-primary ">
              La plataforma que hacil facil organizar tu evento
            </h2>
            <div> Seguro te preguntarás, y ¿cómo funciona? </div>
          </Col>
        </Row>

        <Row className="align-items-center ">
          {/* <> Each Section has 2 columns, where the 1st column is the title + caption, and 2nd column is images. Details component */}
          <Row className="pb-7">
            <Col
              xs={12}
              lg={6}
              className="order-sm-0 order-md-0 order-lg-0 order-xs-0 "
            >
              <p className="fw-bold justify-content-sm-center">
                1.- Busca Proveedores
              </p>
              <div className="text-fiestr-gray pb-3">
                Busca en nuestro catálogo de proveedores evaluados y
                calificados, revisa sus servicios y reseñas, compara entre
                diferentes servicios y proveedores
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="d-inline-flex order-sm-0 order-xs-0 "
            >
              <img className="img-fluid-det m-2" src={muscian} alt="Musician" />
              <img className="img-fluid-det m-2" src={catering} alt="cater" />
              <img
                className="img-fluid-det m-2"
                src={photographer}
                alt="photos"
              />
            </Col>
          </Row>
          <Row className="pb-8">
            <Col
              xs={12}
              lg={6}
              className=" order-sm-0 order-md-0 order-lg-0 order-xs-1"
            >
              <p className="fw-bold">
                2.- Selecciona los servicios que necesitas
              </p>
              <div className="text-fiestr-gray pb-3">
                Agrega los servicios que deseas contratar a tu carrito e indica
                los detalles de tu evento para hacer más fácil tu pedido.
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="d-inline-flex order-sm-0 order-lg-0 order-xs-1"
            >
              <img className="img-fluid-det m-2" src={party} alt="Musician" />
              <img
                className="img-fluid-det m-2"
                src={weddingplan}
                alt="Musician"
              />
              <img className="img-fluid-det m-2" src={review} alt="Musician" />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col
              xs={12}
              lg={6}
              className="order-sm-1 order-md-1 order-lg-0 order-xs-1"
            >
              <p className="fw-bold">3.- Contrata de forma segura</p>
              <div className="text-fiestr-gray pb-3">
                Despreocupate al contratar a tus proveedores y disfruta de tu
                evento!
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="d-inline-flex order-sm-1 order-xs-1 "
            >
              <img
                className="img-fluid-det m-2"
                src={weddingphoto}
                alt="Musician"
              />
              <img
                className="img-fluid-det m-2"
                src={champagne}
                alt="Musician"
              />
              <img className="img-fluid-det m-2" src={bg} alt="Musician" />
            </Col>
          </Row>
        </Row>
      </div>
    </section>
  )
}

export default Details
