import { useAuth0 } from "@auth0/auth0-react"
import Button from "../common/Button"

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  return (
    !isAuthenticated && (
      <Button
        className="btn btn-phoenix-primary order-0"
        onClick={() =>
          loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
        }
      >
        Registrarme
      </Button>
    )
  )
}
export default LoginButton
