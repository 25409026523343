import logo from "./assets/img/icons/logo.svg"

const Logo = () => {
  return (
    <div className={"d-flex align-items-center "}>
      <img src={logo} alt="phoenix" />
    </div>
  )
}

export default Logo
